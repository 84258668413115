<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Cities</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="country">
                    Country
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    v-model="CountryID"
                    @change.native="getStateList($event.target.value)"
                    id="month1"
                    :plain="true"
                    value-field="CountryID"
                    text-field="CountryName"
                    :options="countries"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Country --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="state">
                    State
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    @change.native="getCityList($event.target.value)"
                    v-model="city.StateID"
                    id="month1"
                    :plain="true"
                    value-field="StateID"
                    text-field="StateName"
                    :options="states"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select State --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="title">
                    City Name
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    @change.native="onChangeGetLatLong($event)"
                    required
                    v-model="city.CityName"
                    id="title"
                    placeholder="City Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="latitude">
                    Latitude
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    required
                    type="number"
                    step="any"
                    v-model="city.Latitude"
                    id="latitude"
                    placeholder="Latitude"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="longitude">
                    Longitude
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    required
                    type="number"
                    step="any"
                    v-model="city.Longitude"
                    id="longitude"
                    placeholder="Longitude"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="status">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="city.CityIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

        <b-card>
          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Cities
            <div class="card-header-actions"></div>
          </b-card-header>
          <b-card-body>
            <v-client-table
              :columns="columns"
              :data="data"
              :options="options"
              :theme="theme"
              id="dataTable"
            >
              <div slot="CityIsActive" slot-scope="props">
                <c-switch
                  class="mx-1"
                  color="primary"
                  v-model="props.row.CityIsActive"
                  variant="3d"
                  label
                  v-bind="labelIcon"
                  @change.native="changeStatus(props.row.CityID)"
                />
              </div>
              <div slot="CityID" slot-scope="props">
                <i class="fa fa-edit" @click="editCity(props.row.CityID)"></i>
              </div>
            </v-client-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";
const Jquery = require("jquery");

export default {
  name: "Countries",
  components: {
    cSwitch
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      countryName: "",
      stateName: "",
      city: {
        CityID: null,
        CityName: "",
        StateID: 0,
        Flag: 1,
        Latitude: "",
        Longitude: "",
        CityIsActive: ""
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      CountryID: 0,
      countries: [],
      states: [],
      columns: ["CityName", "CityIsActive", "CityID"],
      data: [],
      options: {
        headings: {
          CityName: "City Name",
          CityIsActive: "Status",
          CityID: "Edit Record"
        },
        sortable: ["CityName"],
        filterable: ["CityName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  async mounted() {
    this.getCountries();
  },
  methods: {
    getCountries: function() {
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.commontext.submitButton = "Submit";

      this.data = [];
      this.city = {
        CityID: null,
        Flag: 1,
        CityName: "",
        StateID: 0,
        Latitude: "",
        Longitude: "",
        CityIsActive: true
      };
      this.CountryID = 0;
      this.states = [];
    },
    getStateList: function(CountryID) {
      this.states = [];
      // let states = await MasterService.getStatesDDL(e);
      let array = this.countries.filter(x => {
        return x.CountryID == CountryID;
      });
      if (array.length) {
        this.countryName = array[0].CountryName;
      }
      let payload = {
        CountryID
      };
      MasterService.getStatesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.states = data.StateList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCityList: function(StateID) {
      // let res = await MasterService.getCities(e);
      let array = this.states.filter(x => {
        return x.StateID == StateID;
      });
      if (array.length) {
        this.stateName = array[0].StateName;
      }
      this.data = [];
      let payload = {
        StateID
      };
      MasterService.getCities(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CityList.length > 0) {
            this.data = data.CityList.reverse();
          } else {
            data.Message ? this.showMessageSnackbar(data.Message) : "";
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.CountryID == "") {
        this.showMessageSnackbar("Please select the Country.");
        return false;
      } else if (this.city.StateID == 0) {
        this.showMessageSnackbar("Please select a State.");
        return false;
      } else if (this.city.CityName == "") {
        this.showMessageSnackbar("City Name Cannot be empty.");
        return false;
      } else if (this.city.Latitude == "") {
        this.showMessageSnackbar("City Latitude Cannot be empty.");
        return false;
      } else if (this.city.Longitude == "") {
        this.showMessageSnackbar("City Longitude Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      // let res = await MasterService.addCity(this.city);
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addCity(this.city)
        .then(response => {
          const { data } = response;
          // this.getCityList(this.city.StateID);
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCity: function(CityID) {
      let payload = {
        CityID
      };
      MasterService.getCityByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CityList.length > 0) {
            this.city = data.CityList[0];
            this.commontext.submitButton = "Update City";
            this.city.Flag = 2;
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onChangeGetLatLong: function(event) {
      // https://maps.googleapis.com/maps/api/geocode/json?address=mumbai&key=AIzaSyCsAiATFqYZMWOxKIwhUbPUaEKjNpnh2Y8
      let self = this;
      let query = "";
      if (this.stateName != "") {
        query += this.stateName.trim().replace(/\s/, "+") + ",";
      }
      if (this.countryName != "") {
        query += this.countryName.trim().replace(/\s/, "+") + ",";
      }
      query += event.target.value.trim().replace(/\s/, "+");
      Jquery.ajax({
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=AIzaSyCsAiATFqYZMWOxKIwhUbPUaEKjNpnh2Y8`,
        method: "GET"
      })
        .then(function(response) {
          const { results } = response;
          if (results && Array.isArray(results) && results.length > 0) {
            const {
              geometry: { location }
            } = results[0];
            self.city.Longitude = location.lng;
            self.city.Latitude = location.lat;
          }
        })
        .catch(function(error) {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "CityMaster");
      let payload = {
        TableName: "CityMaster",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
